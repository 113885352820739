import React from "react";
import { Link } from "react-router-dom";

import "./styleds.scss";

const Navigation = () => {
  var lista = [
    { name: "Home", to: "/app/home", icon: "bi bi-house" },
    { name: "Sair", to: "/", icon: "bi bi-box-arrow-left" }
  ];

  return (
    <div className="navigation">
      <nav>
        <ul>
          {lista.map((item) => (
            <li key={item.name}>
              <Link to={item.to}>
                <i className={item.icon}></i> {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
