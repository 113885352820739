import React from "react";

import "./styleds.scss";

const Header = () => {
  return (
    <header className="header">
      <div />
      <div className="box-btns-menu-header">
        <i className="bi bi-bell"></i>
        <i className="bi bi-person-circle"></i>
      </div>
    </header>
  );
};

export default Header;
