// App.js
import React from "react";
import Router from "./router";

import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/saga-blue/theme.css"; // Escolha o tema desejado
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "assets/styles/cores.css";
import "assets/styles/fromate.scss";

function App() {
  return <Router></Router>;
}

export default App;
