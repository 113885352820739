import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

import "./styleds.scss";

const Home = () => {
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/listed_columns?table_id=1`) // replace 1 with dynamic table_id if necessary
      .then((response) => response.json())
      .then((data) => {
        const { transformedData, uniqueColumns } = transformData(data);
        setRecords(transformedData);
        setColumns(uniqueColumns);
      })
      .catch((error) => console.error("Error fetching columns:", error));
  }, []);

  const transformData = (data) => {
    const records = {};
    const uniqueColumns = new Set();

    data.forEach((item) => {
      uniqueColumns.add(item.column_name);
      if (!records[item.identifier]) {
        records[item.identifier] = { identifier: item.identifier };
      }
      records[item.identifier][item.column_name] = item.user_value;
    });

    return {
      transformedData: Object.values(records),
      uniqueColumns: Array.from(uniqueColumns)
    };
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Link
        to={`/app/tables?identifier=${rowData.identifier}`}
        className="btn-link-tabela"
      >
        <i className="pi pi-search"></i>
      </Link>
    );
  };

  return (
    <div className="card p-3 registros">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Registros</h1>
        <Button
          label="Criar Paciente"
          onClick={() => (window.location.href = "/app/form/1")}
        />
      </div>
      <DataTable value={records}>
        {columns.map((col, index) => (
          <Column key={index} field={col} header={col} />
        ))}
        <Column body={actionBodyTemplate} header="Detalhes" />
      </DataTable>
    </div>
  );
};

export default Home;
