import React from "react";

import Header from "components/Header";
import Navigation from "components/Navigation";

import "./styleds.scss";

const Layout = ({ element: Element }) => {
  return (
    <div className="layout">
      <div className="layout-navigation">
        <Navigation />
      </div>
      <div className="layout-body">
        <Header />
        <main>
          <Element />
        </main>
      </div>
    </div>
  );
};

export default Layout;
