import { useState, useEffect, useCallback, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import "./Form.css";
import {
  Form as BootstrapForm,
  Button,
  Container,
  Row,
  Col
} from "react-bootstrap";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";

var Form = () => {
  const toast = useRef(null);

  const { tableId } = useParams();
  const navigate = useNavigate();
  const [columns, setColumns] = useState([]);
  const [valuesColumns, setValuesColumns] = useState({});
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [tableName, setTableName] = useState("");
  const [identifier, setIdentifier] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const identifierParam = queryParams.get("identifier");
    if (identifierParam) {
      setIdentifier(identifierParam);
    }

    fetch(`${process.env.REACT_APP_API_URL}/columns?table_id=${tableId}`)
      .then((response) => response.json())
      .then((data) => {
        setColumns(data);
        data.forEach((column) => {
          fetch(
            `${process.env.REACT_APP_API_URL}/values?column_id=${column.column_id}`
          )
            .then((response) => response.json())
            .then((values) => {
              setValuesColumns((prev) => ({
                ...prev,
                [column.column_id]: values
              }));
            })
            .catch((error) =>
              console.error(
                `Error fetching values for column ${column.column_id}:`,
                error
              )
            );
        });

        if (identifierParam) {
          fetch(
            `${process.env.REACT_APP_API_URL}/user_responses_by_identifier?table_id=${tableId}&identifier=${identifierParam}`
          )
            .then((response) => response.json())
            .then((data) => {
              const newFormData = {};
              data.forEach((item) => {
                // Verifica se o valor do usuário é um JSON
                try {
                  const parsedValue = JSON.parse(item.user_value);
                  if (Array.isArray(parsedValue)) {
                    newFormData[`${item.column_name}_${item.column_id}`] =
                      parsedValue;
                  } else {
                    newFormData[`${item.column_name}_${item.column_id}`] =
                      item.user_value;
                  }
                } catch (e) {
                  newFormData[`${item.column_name}_${item.column_id}`] =
                    item.user_value;
                }
              });
              setFormData(newFormData);
            })
            .catch((error) =>
              console.error("Error fetching user responses:", error)
            );
        }
      })
      .catch((error) => console.error("Error fetching columns:", error));

    fetch(`${process.env.REACT_APP_API_URL}/tables?table_id=${tableId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setTableName(data[0].table_name);
        }
      })
      .catch((error) => console.error("Error fetching table name:", error));
  }, [tableId]);

  useEffect(() => {
    columns.forEach((column) => {
      const fieldName = `${column.column_name}_${column.column_id}`;
      const inputElement = document.querySelector(`[name="${fieldName}"]`);
      if (inputElement) {
        inputElement.value = formData[fieldName] || "";
      }
    });
  }, [formData, columns]);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const [day, month, year] = birthDate.split("/").map(Number);
    const birth = new Date(year, month - 1, day);
    let age = today.getFullYear() - birth.getFullYear();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();

    if (
      currentMonth < birth.getMonth() ||
      (currentMonth === birth.getMonth() && currentDay < birth.getDate())
    ) {
      age--;
    }

    return age;
  };

  const validateNumber = (name, value) => {
    const column = columns.find((col) => col.column_id == name.split("_")[1]);
    if (!column) return { valid: true, message: "" };

    let numericValue = parseFloat(value.replace(",", "."));
    let min = 0;
    let max = 200;

    if (name.startsWith("Idade")) {
      min = 18;
      max = 100;
      numericValue = calculateAge(value);
    } else {
      const values = valuesColumns[column.column_id] || [];
      if (values.length > 0) {
        const range = values[0].possible_value.match(/\[(\d+);(\d+(\.\d+)?)\]/);
        if (range) {
          min = parseFloat(range[1]);
          max = parseFloat(range[2]);
        }
      }
    }

    if (
      (column.required || value) &&
      (isNaN(numericValue) || numericValue < min || numericValue > max)
    ) {
      return {
        valid: false,
        message: `Valor deve ser numérico entre ${min} e ${max}`
      };
    }

    return { valid: true, message: "" };
  };

  const triggerDoubleClick = (element) => {
    if (element) {
      element.click();
      setTimeout(() => element.click(), 0);
    }
  };

  const handleChange = (e) => {
    try {
      const { name, value, type, checked } = e.target;

      setFormData((prev) => {
        const newData = { ...prev };

        if (type === "checkbox") {
          if (!newData[name]) newData[name] = [];
          if (checked) {
            newData[name] = [...newData[name], value];
          } else {
            newData[name] = newData[name].filter((v) => v !== value);
          }
        } else {
          newData[name] = value;
        }

        return newData;
      });

      const [field, columnId] = name.split("_");
      const column = columns.find((col) => col.column_id == columnId);
      if (column && (column.data_type === "number" || field === "idade")) {
        const validation = validateNumber(name, value);
        if (!validation.valid) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: validation.message
          }));
        } else {
          setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[name];
            return newErrors;
          });
        }
      }

      // Simula clique duplo para checkboxes e radio buttons
      if (type === "radio") {
        triggerDoubleClick(e.target);
      }
    } catch (error) {
      console.error("handleChange :", error);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const validation = validateNumber(name, value);

    if (!validation.valid) {
      setErrors({
        ...errors,
        [name]: validation.message
      });
      setFormData((prev) => ({
        ...prev,
        [name]: ""
      }));
      e.target.focus();
    } else {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const queryParams = new URLSearchParams(window.location.search);
    const identifierParam = queryParams.get("identifier");

    let valid = true;
    const newErrors = {};
    const dataToSend = { table_id: tableId, identifier: identifierParam || "" };

    columns.forEach((column) => {
      const fieldName = `${column.column_name}_${column.column_id}`;
      if (column.required && !formData[fieldName]) {
        valid = false;
        newErrors[fieldName] = "Campo obrigatório";
      } else {
        dataToSend[column.column_id] = formData[fieldName];
      }
    });

    setErrors(newErrors);

    if (valid) {
      fetch(`${process.env.REACT_APP_API_URL}/save_record`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSend)
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            toast.current.show({
              severity: "success",
              summary: "Sucesso",
              detail: "Registro salvo com sucesso"
            });

            var inputCPF = document.querySelector(
              'input[id^="CPF"], input[name^="CPF"]'
            );

            setTimeout(() => {
              if (inputCPF) {
                var valorCPF = inputCPF.value;
                navigate(`/app/tables?identifier=${valorCPF}`);
              } else {
                navigate(`/app/tables?identifier=${identifierParam}`);
              }
            }, 1000);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Erro",
              detail: `Erro: ${data.error}`
            });
          }
        })
        .catch((error) => {
          console.error("Erro ao salvar o registro:", error);
          toast.current.show({
            severity: "error",
            summary: "Erro",
            detail: "Erro ao salvar o registro."
          });
        });
    }
  };

  const handleCheckboxChange = (e, fieldName) => {
    const { value, checked } = e.target;
    setFormData((prev) => {
      const updatedValues = checked
        ? [...(prev[fieldName] || []), value]
        : (prev[fieldName] || []).filter((v) => v !== value);
      return {
        ...prev,
        [fieldName]: updatedValues
      };
    });
  };

  const renderInput = useCallback(
    (column) => {
      const values = valuesColumns[column.column_id] || [];
      const fieldName = `${column.column_name}_${column.column_id}`;

      if (column.column_name.toLowerCase() === "cpf") {
        return (
          <InputMask
            id={fieldName}
            mask="999.999.999-99"
            value={formData[fieldName]}
            onChange={(e) =>
              handleChange({ target: { name: fieldName, value: e.value } })
            }
          />
        );
      } else if (column.column_name.toLowerCase() === "idade") {
        return (
          <InputMask
            mask="99/99/9999"
            value={formData[fieldName]}
            onChange={(e) =>
              handleChange({ target: { name: fieldName, value: e.value } })
            }
            onBlur={handleBlur}
          />
        );
      } else if (
        values.length === 0 ||
        values.some(
          (v) =>
            v.possible_value === "AUTO_PREENCHIMENTO" ||
            v.possible_value === "CAMPO_ABERTO"
        )
      ) {
        return (
          <InputText
            name={fieldName}
            value={formData[fieldName] || ""}
            onChange={(e) =>
              handleChange({
                target: { name: fieldName, value: e.target.value }
              })
            }
            required={column.required}
            size="sm"
          />
        );
      } else if (
        values.some((v) => /^\[\d+;\d+(\.\d+)?\]$/.test(v.possible_value))
      ) {
        const range = values[0].possible_value.match(/\[(\d+);(\d+(\.\d+)?)\]/);
        const min = parseFloat(range[1]);
        const max = parseFloat(range[2]);
        return (
          <>
            <BootstrapForm.Label className="small-text">
              (min: {min}, max: {max})
            </BootstrapForm.Label>
            <InputNumber
              name={fieldName}
              value={formData[fieldName] || ""}
              min={min}
              max={max}
              minFractionDigits={2}
              maxFractionDigits={2}
              onValueChange={(e) =>
                handleChange({ target: { name: fieldName, value: e.value } })
              }
              onBlur={handleBlur}
              required={column.required}
              size="sm"
              mode="decimal"
              locale="pt-BR"
              useGrouping={false}
            />
          </>
        );
      } else if (column.allow_multiple_responses) {
        return (
          <div className="d-flex flex-wrap gap-3">
            {values.map((v) => (
              <div key={v.value_id} className="d-flex align-items-center gap-2">
                <Checkbox
                  inputId={v.value_id}
                  name={fieldName}
                  value={v.possible_value}
                  onChange={(e) => handleCheckboxChange(e, fieldName)}
                  checked={(formData[fieldName] || []).includes(
                    v.possible_value
                  )}
                />
                <label
                  htmlFor={v.value_id}
                  className="p-checkbox-label p-inputtext-sm"
                >
                  {v.possible_value}
                </label>
              </div>
            ))}
          </div>
        );
      } else if (
        values.some(
          (v) =>
            v.possible_value.toLowerCase() === "sim" ||
            v.possible_value.toLowerCase() === "não"
        )
      ) {
        return (
          <div className="d-flex flex-row gap-3">
            {values.map((v, i) => (
              <div
                key={v.value_id}
                className="d-flex flex-row align-items-center gap-2"
              >
                <RadioButton
                  inputId={fieldName + "_" + i}
                  name={fieldName}
                  value={v.possible_value}
                  onChange={(e) =>
                    handleChange({
                      target: { name: fieldName, value: e.value }
                    })
                  }
                  checked={formData[fieldName] === v.possible_value}
                  required={column.required}
                />
                <label
                  htmlFor={fieldName + "_" + i}
                  className="p-radiobutton-label p-inputtext-sm"
                >
                  {v.possible_value}
                </label>
              </div>
            ))}
          </div>
        );
      } else {
        return (
          <Dropdown
            name={fieldName}
            value={formData[fieldName] || ""}
            options={values.map((v) => ({
              label: v.possible_value,
              value: v.possible_value
            }))}
            onChange={(e) =>
              handleChange({ target: { name: fieldName, value: e.value } })
            }
            placeholder="Selecione"
            required={column.required}
            className="p-inputtext-sm"
          />
        );
      }
    },
    [formData]
  );

  return (
    <Container>
      <Toast ref={toast} />
      <div className="card p-3">
        <h1 className="text-center mb-4">Formulário para {tableName}</h1>

        <BootstrapForm onSubmit={handleSubmit}>
          <Row>
            {columns.map((column) => (
              <Col md={6} key={column.column_id}>
                <BootstrapForm.Group className="d-flex flex-column mb-3 ">
                  <BootstrapForm.Label>
                    {column.column_name}
                  </BootstrapForm.Label>
                  {renderInput(column)}
                  {errors[`${column.column_name}_${column.column_id}`] && (
                    <div className="text-danger small">
                      {errors[`${column.column_name}_${column.column_id}`]}
                    </div>
                  )}
                </BootstrapForm.Group>
              </Col>
            ))}
          </Row>
          <div className="d-flex justify-content-end gap-3">
            <Link
              to={`/app/tables?identifier=${identifier}`}
              className="btn btn-secondary"
            >
              Voltar
            </Link>
            <Button type="submit" variant="primary">
              Salvar
            </Button>
          </div>
        </BootstrapForm>
      </div>
    </Container>
  );
};

export default Form;
