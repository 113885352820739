import React, { useState, useEffect } from 'react';

function SavedRecords() {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    fetch('/user_responses')
      .then(response => response.json())
      .then(data => setRecords(data));
  }, []);

  return (
    <div>
      <h1>Saved Records</h1>
      <div>
        {records.map(record => (
          <div key={record.response_id} className="card">
            <p>Column ID: {record.column_id}</p>
            <p>User Value: {record.user_value}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SavedRecords;
