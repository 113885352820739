import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet
} from "react-router-dom";

import Home from "pages/home";
import Tables from "pages/tables";
import Form from "pages/form";
import SavedRecords from "pages/savedRecords";
import Login from "pages/login";
import Layout from "components/Layout";

const MainRouter = () => {
  const routeConfig = createBrowserRouter([
    { path: "/", element: <Navigate to="/login" /> },
    { path: "login", element: <Login /> },
    {
      path: "app",
      element: <Layout element={Outlet}></Layout>,
      children: [
        { path: "home", element: <Home /> },
        { path: "tables", element: <Tables /> },
        { path: "form/:tableId", element: <Form /> },
        { path: "saved-records", element: <SavedRecords /> }
      ]
    }
  ]);

  return <RouterProvider router={routeConfig} />;
};

export default MainRouter;
