import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styleds.scss";

const Login = () => {
  const navigate = useNavigate();

  const [Erro, setErro] = useState(false);

  var SeLogar = (event) => {
    event.preventDefault();
    setErro(false);
    const formData = new FormData(event.target);

    // Transformando dataLogin em uma lista
    var dataLoginList = [
      {
        usuario: "brianmonteiro@akkadiantech.com.br",
        senha: "123456"
      },
      {
        usuario: "omar.mejia",
        senha: "123456"
      },
      {
        usuario: "usuario.teste",
        senha: "123456"
      }
    ];

    const data = {
      usuario: formData.get("usuario"),
      senha: formData.get("senha")
    };

    setTimeout(() => {
      const usuarioValido = dataLoginList.some(
        (login) => login.usuario === data.usuario && login.senha === data.senha
      );
      if (usuarioValido) {
        navigate("/app/home");
      } else {
        setErro(true);
      }
    }, 100);
  };

  return (
    <div className="login">
      <div className="c-1" />
      <div className="c-2" />
      <div className="card p-3 d-flex flex-column gap-3">
        <h1 className="fs-3">Bem vindo(a) Cardux MICS</h1>
        <p className="m-0">Por favor, entre com os seus dados de acesso!</p>
        <form className="form-login" onSubmit={SeLogar}>
          <div className="mb-3">
            <label for="usuario" className="form-label">
              Usuario
            </label>
            <input
              type="usuario"
              name="usuario"
              className="form-control"
              id="usuario"
            />
          </div>
          <div className="mb-3">
            <label for="senha" className="form-label">
              Senha
            </label>
            <input
              type="password"
              name="senha"
              className="form-control"
              id="senha"
            />
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
            />
            <label className="form-check-label" for="exampleCheck1">
              Lembra de mim
            </label>
          </div>
          {Erro && (
            <div class="alert alert-danger" role="alert">
              <p className="m-0">Dados inválidos</p>
            </div>
          )}
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-primary">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
