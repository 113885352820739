import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import "./Tables.css";
import { Card, Container, Row, Col, Button } from "react-bootstrap";

function Tables() {
  const { groupId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const identifier = queryParams.get("identifier");
  const [tables, setTables] = useState([]);
  const [patientInfo, setPatientInfo] = useState({});

  const icons = [
    "pi pi-table",
    "pi pi-clipboard",
    "pi pi-book",
    "pi pi-chart-bar",
    "pi pi-cog",
    "pi pi-heart",
    "pi pi-file",
    "pi pi-chart-pie",
    "pi pi-calendar",
    "pi pi-shield",
    "pi pi-comment"
  ];

  useEffect(() => {
    if (identifier) {
      fetch(
        `${process.env.REACT_APP_API_URL}/patient_info?identifier=${identifier}`
      )
        .then((response) => response.json())
        .then((data) => setPatientInfo(data))
        .catch((error) => console.error("Error fetching patient info:", error));
    }

    fetch(`${process.env.REACT_APP_API_URL}/tables?group_id=${groupId}`)
      .then((response) => response.json())
      .then((data) => setTables(data))
      .catch((error) => console.error("Error fetching tables:", error));
  }, [identifier, groupId]);

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="text-center">Categorias</h1>
        <div className="patient-info text-right">
          <p>
            <strong>CPF:</strong> {patientInfo.cpf}
          </p>
          <p>
            <strong>Nome:</strong> {patientInfo.name}
          </p>
        </div>
      </div>

      <Row>
        {tables.map((table, index) => (
          <Col md={4} sm={6} xs={12} key={table.table_id} className="mb-4">
            <Card className="h-100 table-card">
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <i
                  className={`pi ${icons[index]}`}
                  style={{ fontSize: "2rem" }}
                ></i>
                <Card.Title className="mt-3">{table.table_name}</Card.Title>
                <Link
                  to={`/app/form/${table.table_id}?identifier=${identifier}`}
                  className="stretched-link"
                ></Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div className="d-flex justify-content-end">
        <Link to="/app/home">
          <Button variant="secondary">Voltar</Button>
        </Link>
      </div>
    </Container>
  );
}

export default Tables;
